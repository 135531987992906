<template>
    <section class="ver-cotizacion-proveedor container-fluid overflow-auto scroll-none">
        <navbar-admin titulo="Alamacén > Nueva planta Colombia > Cotización 0001" />
        <div class="row my-4">
            <div class="col-12 col-md-auto col-lg-12 col-xl-11">
                <div class="bg-f5 border br-8 pr-4 br-50-6">
                    <div class="row mx-0 contenedor-info d-middle">
                        <img :src="detalleCotizacion.imagen | helper-storage" alt="" class="img-logo-op wh-126 rounded-circle position-relative">
                        <div class="col py-2">
                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="row">
                                        <div class="col-auto">
                                            <p class="f-18 f-700">{{ detalleCotizacion.referencia }}</p>
                                        </div>
                                        <div class="col-auto">
                                            <div class="d-middle">
                                                <i class="icon-file-send text-green" />
                                                <p class="f-14 f-600">Recibido:  <span class="f-300">{{ detalleCotizacion.fecha_cotizacion | helper-fecha('DD MMM YYYY')  }}</span> </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="f-14 f-500 my-2">{{ detalleCotizacion.proveedor }}</p>
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="d-middle">
                                                <i class="icon-account" />
                                                <p class="font-italic f-14">{{ detalleCotizacion.responsable }}</p>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div class="d-middle">
                                                <el-tooltip content="Número de equipos" placement="bottom" effect="light" visible-arrow>
                                                    <i class="icon-format-list-numbered" />
                                                </el-tooltip>
                                                <p class="font-italic f-14">{{ detalleCotizacion.materiales_cotizados }} / {{ detalleCotizacion.total_materiales }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col py-2">
                            <div class="row">
                                <div class="col-12 col-lg-12 text-right">
                                    <p class="f-18 f-700">Total {{formatoMoneda(detalleCotizacion.valor_total)}}</p>
                                    <p class="f-14 f-600 my-2">Días de crédito:  <span class="f-300">{{ detalleCotizacion.dias_credito }} días</span> </p>
                                    <p class="f-14 f-600 my-2">Vigencia de precio:  <span class="f-300">{{ detalleCotizacion.dias_vigencia_precio }} días</span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-11">
                <div class="bg-f5 border br-8 p-3">
                    <div class="d-middle">
                        <i class="icon-message-reply-text" />
                        <p class="f-14 f-500">Comentario</p>
                    </div>
                    <p class="f-12 lh-17">{{ detalleCotizacion.comentarios }}</p>
                </div>
            </div>
        </div>
        <titulo-divisor titulo="Materiales cotizados">
            <div class="row">
                <div class="col auto">
                    <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar material" size="small" />
                </div>
                <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="handleClearFilter"/>
            </div>
        </titulo-divisor>
        <tabla-general
            :mostrarBuscador="false"
            :usar-servidor="true" 
            :servidor-data="cotizacionesMateriales"
            @paginar="paginate"
            >
            <el-table-column label="Nombre" prop="name" width="320">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="type" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M" prop="um" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.unidad_medida }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cantidad" prop="cant" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cantidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor unitario" prop="unitValue" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatMoney(scope.row.valor_unidad, scope.row.id_moneda) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor Parcial" prop="parcialValue" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatMoney(scope.row.valor_parcial, scope.row.id_moneda) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Días de entrega" prop="days" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.dias_entrega }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Garantía" prop="warranty" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.garantia }} meses
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-3">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" placeholder="Seleccionar tipo" size="small" class="w-100">
                        <el-option v-for="item in select_material_types" :key="item.id" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filter.id_unidad_medida" placeholder="Seleccionar unidad" size="small" class="w-100">
                        <el-option v-for="item in select_measure_units" :key="item.id" :label="`${item.unidad_medida} (${item.sigla})`" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <el-checkbox v-model="filter.materiales_no_cotizados" label="Materiales no cotizados" :true-label="1" :false-label="0"/>
                    <el-checkbox v-model="filter.materiales_cotizados" label="Materiales cotizados" :true-label="1" :false-label="0"/>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'


export default {
    data(){
        return{
        }
    },
    computed: {
        ...mapGetters({
            select_material_types: 'selects/selects/select_material_types',
            select_measure_units: 'selects/selects/select_measure_units',
            select_monedas: 'selects/selects/select_monedas',
            detalleCotizacion: 'proveedores/proveedor-cotizacion/detalleCotizacion',
            cotizacionesMateriales: 'proveedores/proveedor-cotizacion/cotizacionesMateriales',
        }),
        filter: {
            get(){
                return this.$store.getters['proveedores/proveedor-cotizacion/filter']
            }, 
            set(val){
                this.$store.commit('proveedores/proveedor-cotizacion/set_filter', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['proveedores/proveedor-cotizacion/query']
            }, 
            set(val){
                this.$store.commit('proveedores/proveedor-cotizacion/set_query', val)
            }
        },
        filterCount(){
            return this.filter.count()
        },
    },
    async mounted() {
        this.getSelects(['Action_get_select_material_types', 'Action_get_select_measure_units', 'Action_get_select_monedas'])
        await this.Action_detalle_cotizacion({ idProveedor: this.$route.params.id_proveedor, idCotizacion: this.$route.params.id_cotizacion })
        this.clear_filter()
        await this.Action_detalle_materiales_cotizacion({ idProveedor: this.$route.params.id_proveedor, idCotizacion: this.$route.params.id_cotizacion })
        this.getBreadcumbs(['proveedores.listado']);
        this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs',[
            {
                position: 2,
                breadcumb: {
                    name: this.detalleCotizacion.proveedor,
                    route: 'proveedores.ver.cotizaciones',
                    params: { id_proveedor: this.$route.params.id_proveedor }
                }
            },
            {
                position: 3,
                breadcumb: {
                    name: this.detalleCotizacion.referencia,
                    route: 'proveedores.ver.cotizaciones.ver',
                    params: { id_proveedor: this.$route.params.id_proveedor, id_cotizacion: this.$route.params.id_cotizacion }
                }
            }
        ])
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            getSelects: 'selects/selects/sync',
            clear_filter: 'proveedores/proveedor-cotizacion/clear_filter',
            Action_detalle_cotizacion: 'proveedores/proveedor-cotizacion/Action_detalle_cotizacion',
            Action_detalle_materiales_cotizacion: 'proveedores/proveedor-cotizacion/Action_detalle_materiales_cotizacion'
        }),
        beforeSearch() {
            this.Action_detalle_materiales_cotizacion({ idProveedor: this.$route.params.id_proveedor, idCotizacion: this.$route.params.id_cotizacion })
        },
        async handleClearFilter() {
            await this.clear_filter()
            this.Action_detalle_materiales_cotizacion({ idProveedor: this.$route.params.id_proveedor, idCotizacion: this.$route.params.id_cotizacion })
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        filtrarMateriales() {
            this.Action_detalle_materiales_cotizacion({ idProveedor: this.$route.params.id_proveedor, idCotizacion: this.$route.params.id_cotizacion })
            this.$refs.abrirModalFiltros.toggle()
        },
        async paginate(page = 1) {
            this.Action_detalle_materiales_cotizacion({ idProveedor: this.$route.params.id_proveedor, idCotizacion: this.$route.params.id_cotizacion, page })
        },
        async limpiarFiltro() {
            await this.clear_filter()
            this.Action_detalle_materiales_cotizacion({ idProveedor: this.$route.params.id_proveedor, idCotizacion: this.$route.params.id_cotizacion })
            this.$refs.abrirModalFiltros.toggle()
        },
        formatMoney(valor, moneda) {
            return `${this.select_monedas.find(el => el.id === moneda)?.sigla} $${valor}`
        },
    }
}
</script>

<style lang="scss" scoped>
.ver-cotizacion-proveedor{
    background: white;
    height: calc(100vh - 225px);
    .br-50-6{
        border-radius: 50rem 6rem 6rem 50rem !important;
    }
    .contenedor-info{
        .img-logo-op{
            left: -3px;
            top: -1px;
        }
    }
}
</style>